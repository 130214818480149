import React from "react";
import styled from "styled-components";
import AppTile from "./AppTile";

const DashboardApplications = ({ apps }) => {
  const hmpApp = apps.find(app => app.identifier === "hmp");
  const rmipApp = apps.find(app => app.identifier === "rmip");

  const nonHmpApps = apps.filter(app => app.identifier !== "hmp");

  return (
    <DashboardContainer>
      {hmpApp && <AppTile data={hmpApp} key={`tile_hmp`} isHmp />}
      {rmipApp && <AppTile data={rmipApp} key={`tile_rmip`} isRmip />}
      {nonHmpApps.map(app => (
        <AppTile data={app} key={`tile_${app.identifier}`} />
      ))}
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 330px);
  gap: 16px;
  padding: 40px;
  justify-content: center;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 330px); /* 3 cards per row */
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 330px); /* 2 cards per row */
  }
`;

export default DashboardApplications;
